/* eslint-disable no-underscore-dangle */
import { QueryFunctionContext } from '@tanstack/react-query'

/* eslint-disable import/no-cycle */
import api, { QUERY_KEYS } from '~api'

import { TBBlockType, TBChartTrendType } from './enums'
import formatChartData from './formatChartData'

export const customDashboardQueryFn = ({ queryKey }: QueryFunctionContext) => {
  const [endpoint, id, rid] = queryKey

  return api
    .get<TBCustomerDashboardResponse.RootObject>(`${endpoint}/${id}`, {
      params: { data_url: true, rid },
    })
    .then(({ data }) => {
      const {
        report: { name, elements, global_filters, competitive_set },
      } = data

      const { benchmark, brand } = global_filters
      const chartBlocks: TBChartBlock[] = elements
        .map(({ id: elementId, chart, separator }) =>
          // eslint-disable-next-line no-nested-ternary
          chart
            ? ({
                id: elementId,
                blockType: TBBlockType.CHART,
                chartOptions: {
                  metricName: chart.chart,
                  metricType: chart.id,
                  chartType: chart.type,
                  chartFilters: { ...chart.filters },
                  topicName: chart.topic,
                  dataUrl: chart.data_url,
                },
              } as TBChartBlock)
            : separator
            ? ({
                id: elementId,
                blockType: TBBlockType.DIVIDER,
                dividerOptions: { text: separator.text },
              } as TBDividerBlock)
            : null
        )
        .filter((chartBlock) => chartBlock) as TBChartBlock[]

      return {
        settings: {
          dashboardName: name,
          benchmark: { label: benchmark, value: benchmark },
          brand: { label: brand, value: brand },
          competitiveSet: competitive_set,
        },
        blocks: chartBlocks,
      }
    })
}

export const customChartUrlQueryFn = ({ queryKey }: any) => {
  const [endpoint, params] = queryKey
  const { trend } = params

  const isPIT = trend === TBChartTrendType.POINT_IN_TIME

  let cols = ''
  let format = ''
  let bbrand = ''
  let bengagement = ''
  let bgeo = ''
  let brand = ''
  let engagement = ''
  let setId = ''
  let trendParam = ''
  let name = ''
  let benchmark = ''
  let bip = ''
  let visitation = ''
  let geo = ''
  let _geo = ''
  let _benchmark = ''
  let order = ''
  let daypart = ''
  let vertical = ''
  let topbox = ''
  let channel = ''
  let buyer_status = ''
  if (endpoint?.fmt) format = `&fmt=${endpoint.fmt}`
  if (endpoint?.bbrand) bbrand = `&bbrand=${endpoint.bbrand}`
  if (endpoint?.bengagement)
    bengagement = `&bengagement=${endpoint.bengagement}`
  if (endpoint?.bgeo) bgeo = `&bgeo=${endpoint.bgeo}`
  if (endpoint?.brand) brand = `&brand=${endpoint.brand}`
  if (endpoint?.engagement) engagement = `&engagement=${endpoint.engagement}`
  if (endpoint?.set_id) setId = `&set_id=${endpoint.set_id}`
  if (endpoint?.trend) trendParam = `&trend=${endpoint.trend}`
  if (endpoint?.name) name = `&name=${endpoint.name}`
  if (endpoint?.benchmark) benchmark = `&benchmark=${endpoint.benchmark}`
  if (endpoint?.bip !== undefined) bip = `&bip=${endpoint.bip}`
  if (endpoint?.visitation) visitation = `&visitation=${endpoint.visitation}`
  if (endpoint?.geo) geo = `&geo=${endpoint.geo}`
  if (endpoint?._geo) _geo = `&_geo=${endpoint._geo}`
  if (endpoint?._benchmark) _benchmark = `&_benchmark=${endpoint._benchmark}`
  if (endpoint?.order) order = `&order=${endpoint.order}`
  if (endpoint?.daypart) daypart = `&daypart=${endpoint.daypart}`
  if (endpoint?.vertical) vertical = `&vertical=${endpoint.vertical}`
  if (endpoint?.topbox !== undefined) topbox = `&topbox=${endpoint.topbox}`
  if (endpoint?.channel) channel = `&channel=${endpoint.channel}`
  if (endpoint?.buyer_status)
    buyer_status = `&buyer_status=${endpoint.buyer_status}`
  if (endpoint?.cols) {
    endpoint?.cols?.forEach((it: any) => {
      cols += `${it.field}=${encodeURIComponent(it.value)};`
    })
    cols = `&cols=${cols.slice(0, cols.lastIndexOf(';'))}`
  }
  const urlPath = `${endpoint.pathname}?period=${endpoint.period}${cols}${geo}${_geo}${_benchmark}&start_date=${endpoint.start_date}&end_date=${endpoint.end_date}${format}${bbrand}${bengagement}${bgeo}${brand}${engagement}${setId}${trendParam}${name}${benchmark}${bip}${visitation}${order}${daypart}${vertical}${topbox}${channel}${buyer_status}`
  return api.get(urlPath).then((data) =>
    formatChartData({
      ...params,
      data: data?.data,
      isPIT,
    })
  )
}

export const postDashboardQueryFn = (requestJson: any) =>
  api
    .post(QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD, requestJson, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)

export const putDashboardQueryFn = ({ id, requestJson }: any) =>
  api.put(`${QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD}/${id}`, requestJson, {
    headers: {
      'Content-Type': 'application/json',
    },
    // Allow 304 - Not Modified
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 304,
  })

export const deleteDashboardQueryFn = (id: string | number) =>
  api
    .delete(`${QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD}/${id}`)
    .then((res) => res?.data)
